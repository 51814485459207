


























































import { Notify,Dialog,Toast } from "vant"
import { IndexStore } from "@/store/modules/Index"
import { CouponStore } from "@/store/modules/Basics/Coupon"
import { ShopOrderSucType } from "@/Type/index"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { Vue,Component, Emit, Prop } from "vue-property-decorator"

interface OrderItemType<T=string>{
    DefaultIcon:T;
    RightIcon:T;

    handleClickPay(e:any):void;
    handleToPathSuc(e:any):void;
    handleToOrderSuc():void;
    handleFilterState():string;
    handleRecurOrder(e:any):void;
    handleFilterFooter():any;
    handleFilterDate():string;
    handleAppointTime(hours:number,fen:number):Array<number>;
    handleComputedTime( AppointList:Array<number>,TimeList:Array<number> ):string;
    handleFilterStrTime(num:number):string;
    handleError(data:string):void;
}

@Component({ name:"OrderItem" })
export default class OrderItem extends Vue implements OrderItemType{
    DefaultIcon = require("$icon/Shop/TestShop.png");
    RightIcon = require("$icon/Basics/Right.png");

    handleClickPay(e:any){ // 支付订单详情
       Dialog.confirm({
           title: '温馨提醒',
           message: '请到订详情支付订单',
           confirmButtonText:"确定支付",
           cancelButtonText:"不用了",
           className:"HideClassName",
           overlayClass:"MaskHideClassName"
       }).then(res=>{
           this.$router.push({
               path:"/orderSuc",
               query:{
                   orderId:this.GetData.orderId as string,
               }
           })
       }).catch(cat=>cat)
       e.preventDefault();
       e.stopPropagation();
    }

    handleToOrderSuc(){ // 跳转订单详情
       if( ShopSucStore.GetShopSuc.id != this.GetData.id ){
           ShopSucStore.SetOnShopList( [] )
           ShopSucStore.SetAppointShop( false )
       }
        this.$router.push({
           path:"/orderSuc",
           query:{
               orderId:this.GetData.orderId as string,
           }
        })
    }


    handleToPathSuc(e:any){// 跳转到商铺
        if( Number( this.GetData.storeStatus ) > 0 ){
            let str = ""
           switch( Number( this.GetData.status ) ){
               case 1:
                   str = "商铺已下架"
                   break;
               case 2:
                   Dialog.confirm({
                       title: '温馨提醒',
                       message: '店铺已歇业,是否调转到店铺详情？',
                       confirmButtonText:"确定",
                       cancelButtonText:"取消",
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(res=>{
                       this.$router.push({
                           path:"/shopSuc",
                           query:{
                               id:this.GetData.storeId as string,
                               title:this.GetData.name as string,
                           }
                       })
                   }).catch( cat=>cat )
                   this.PullClick({ data:this.GetData,index:this.GetIndex })
                   e.preventDefault();
                   e.stopPropagation();
                   return false;
               case 3:
                   str = "商铺未通过审核"
                   break;
           }
           Toast({
               message:str,
               icon:"none",
               className:"ToastClassName"
           })
        }else{
            this.$router.push({
               path:"/shopSuc",
               query:{
                   id:this.GetData.storeId as string,
                   title:this.GetData.name as string,
               }
           })
        }
        this.PullClick({ data:this.GetData,index:this.GetIndex })
        e.preventDefault();
        e.stopPropagation();
    }

    handleRecurOrder(e:any){
       if( Number( this.GetData.storeStatus ) > 0 ){
           let str = ""
           switch( Number( this.GetData.storeStatus ) ){
               case 1:
                   str = "商铺已下架"
                   break;
               case 2:
                   Dialog.confirm({
                       title: '温馨提醒',
                       message: '店铺已歇业,是否调转到店铺详情？',
                       confirmButtonText:"确定",
                       cancelButtonText:"取消",
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(res=>{
                       this.$router.push({
                           path:"/shopSuc",
                           query:{
                               id:this.GetData.storeId as string,
                               title:this.GetData.name as string,
                               recur:"true",
                               List:JSON.stringify( this.GetData.orderDetailList || [] )
                           }
                       })
                   }).catch( cat=>cat )
                   return;
               case 3:
                   str = "商铺未通过审核"
                   break;
           }
           Toast({
               message:str,
               icon:"none",
               className:"ToastClassName"
           })
       }else{
           if( this.GetData.storeType == 0 ){
               IndexStore.SetShopState({
                   state:0,
                   title: '校园外卖',
                   type:0
               })
               CouponStore.SetSelCoupon({})
           }
           this.$router.push({
               path:"/shopSuc",
               query:{
                   id:this.GetData.storeId as string,
                   title:this.GetData.name as string,
                   recur:"true",
                   List:JSON.stringify( this.GetData.orderDetailList || [] )
               }
           })
       }

       e.preventDefault();
       e.stopPropagation();
    }

    handleFilterState(){
        switch( this.GetData.status ){
            case 0:
                return "待付款";
            case 1:
                return "待接单";
            case 2:
                return "待配送";
            case 3:
                return "待取货";
            case 4:
                return "配送中";
            case 5:
                return "已完成";
            case 6:
                return "已取消";
            case 7:
                return "已失效";
            case 8:
                return "已退款";
            case 9:
                return "异常订单";
            default :
                return "啥也不是"
        }
    }

    handleFilterFooter(){
        switch( this.GetData.status ){
            case 1:
                return "等待商家接单中..."
            case 2:
                return "等待配送员接单中..."
            case 3:
               return this.handleFilterDate()
            case 4:
               return this.handleFilterDate();
        }
    }

    handleFilterDate(){//2021-01-22 07:56:18
        let date = new Date()
        let year = date.getFullYear()
        let moth = date.getMonth() + 1
        let day = date.getDate()
        let Hours = date.getHours()
        let Fen = date.getMinutes()
        let dateTime = (this.GetData!.updateTime + "").split(" ")
        let MothTime = dateTime[0].split("-")
        let HoursTime = dateTime[1].split(":")
        let AppointTime = this.handleAppointTime( Number( HoursTime[0]),Number(HoursTime[1]) )
        if( year == Number( MothTime[0] ) ){
            if( moth == Number( MothTime[1] ) ){
                if( day == Number( MothTime[2] ) ){
                    return this.handleComputedTime(AppointTime,[Hours,Fen])
                }else{
                    if( ( day - Number( MothTime[2] ) ) < 2 ){
                        return this.handleComputedTime(AppointTime,[Hours,Fen])
                    }else{
                        return "如疑问请拨打55880688"
                    }
                }
            }else{
                if( Number( MothTime[1] ) == 12 && moth == 1 ){
                    if( Number( MothTime[2] ) < 5 ){
                        return this.handleComputedTime(AppointTime,[Hours,Fen])
                    }else{
                        return "如疑问请拨打55880688"
                    }
                }else{
                    return "如疑问请拨打55880688"
                }
            }
        }else{
            let Num = year - Number( MothTime[0] )
            if( Num == 1 ){
                if( Number( MothTime[1] ) == 12 && moth == 1 ){
                    if( Number( MothTime[2] ) < 5 ){
                        return this.handleComputedTime(AppointTime,[Hours,Fen])
                    }else{
                        return "如疑问请拨打55880688"
                    }
                }else{
                    return "如疑问请拨打55880688"
                }
            }else{
               return "如疑问请拨打55880688"
            }
        }
    }

    handleAppointTime(hours:number,fen:number){
        let Fen = fen + 30;
        let Shi = hours
        if( Fen > 59 ){
            Shi += 1
            Fen = Fen % 60
        }
        return [ Shi,Fen, ]
    }

    handleComputedTime( AppointList:Array<number>,TimeList:Array<number> ){
        let Num = Number( AppointList[0] + "" + AppointList[1] ) - Number( TimeList[0] + "" + TimeList[1] )
        if( Num > 0 ){
            return `预计${AppointList[0]}:${this.handleFilterStrTime(AppointList[1])}(${Num}分钟之后)`
        }else{
            return `预计${AppointList[0]}:${this.handleFilterStrTime(AppointList[1])}(超时${ ( Math.abs(Num) > 59 ) ? Math.abs(Num)+'分钟' : Math.floor( Math.abs(Num) / 2 ) + '小时' + Math.abs( Math.abs(Num) % 2 ) + "分钟"})`
        }
    }

    handleFilterStrTime(num:number){
        if( num < 10 ){
            return "0" + num
        }else{
            return num + ""
        }
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Prop(Object)
   data!:ShopOrderSucType

   get GetData(){
       return this.data
   }

   @Prop(Number)
   index!:number

   get GetIndex(){
       return this.index
   }

   @Emit("PullClick")
   PullClick( data:any ){
       return data
   }
}
