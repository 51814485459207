















































 import { Notify } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import List from "@/components/ShareComponent/PullDownUp.vue"
 import ListItem from "@/components/ShopComponent/OrderItem.vue"
 import ListFooter from "@/components/ShareComponent/ListBottom.vue"
 import ScrollX from "@/components/ShareComponent/ScrollViewX.vue"
 import { MyOrderList } from "@/Api/Shop/index"
 import { MyOrderListType } from "@/Api/Shop/interfaceType"
 import Stroage from "@/util/Storage"
 import { ShopOrderSucType } from "@/Type/index"
 import { IndexStore } from "@/store/modules/Index"
 import { ShopStore } from "@/store/modules/Shop/Shop"
 import { Vue,Component, Watch } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";

 interface NavListType<T=string>{
     text:T;
     state:T|number
 }

 interface OrderListType<T=string>{
     loadingShow:boolean;
     userId:T|number;
     token:T;
     ScrollTop:number;
     NavList:Array<NavListType>
     NavIndex:number;
     List:Array<ShopOrderSucType>
     UpData:MyOrderListType
     StopUpDown:boolean
     UpDown:boolean;
     PullDown:boolean

     init():void;
     initData():void;
     handleClickToPath(data:any):void;
     handleChangeNavIndex(data:NavListType,index:number):void;
     handlePullDown(data?:any):void;
     handleUpDown(data?:any):void
     handleUpData(bool?:boolean):void;
     handleError(data:string):void;
 }

 @Component({ name:"OrderList",components:{ HeadTop,List,ListItem,ListFooter,ScrollX } })
 export default class OrderList extends ZoomPage implements OrderListType{
    loadingShow = true;
    userId:string|number = ""
    token = ""
    ScrollTop = 0;
    NavList = [
        { text:"全部",state:"-1" },
        { text:"待支付",state:"0" },
        { text:"待接单",state:"1" },
        { text:"待配送",state:"2" },
        { text:"待取货",state:"3" },
        { text:"带送达",state:"4" },
        { text:"已完成",state:"5" },
        { text:"已取消",state:"6" },
        { text:"异常",state:"9" },
        { text:"其他",state:"7,8" }
    ]
    NavIndex = 0
    UpData:MyOrderListType = {
        pageNo:1,
        pageSize:15
    }
    List:Array<ShopOrderSucType> = []
    StopUpDown = false // 是否禁止上啦加载
    UpDown = true // 上啦加载状态
    PullDown = false // 下拉加载状态

    mounted() {
        this.loadingShow = false
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.initData()
    }

    init(){
        let time = setTimeout(()=>{
            switch( IndexStore.GetShopState.type ){
                case 2:
                    this.ScrollTop = ShopStore.GetShopScroll.MySnackScroll
                    break;
                case 0:
                    this.ScrollTop = ShopStore.GetShopScroll.MyEatScroll
                    break;
                case 1:
                    this.ScrollTop = ShopStore.GetShopScroll.MyShopScroll
                    break;
                case 3:
                    this.ScrollTop = ShopStore.GetShopScroll.MyFreshScroll
                    break;
            }
            clearTimeout( time )
        },200)

    }

    initData(){
         this.UpData.storeType = IndexStore.GetShopState.type
         switch( IndexStore.GetShopState.type ){
             case 2:
                 if( ShopStore.GetMyShopList.MySnackList.length ){
                     this.List = ShopStore.GetMyShopList.MySnackList
                     this.UpData = ShopStore.GetMyUpData.MySnackUpData
                     this.loadingShow = false
                     this.UpDown = false
                     this.init()
                 }else{
                     this.handleUpData(true)
                 }
                 break;
             case 0:
                 if( ShopStore.GetMyShopList.MyEatList.length ){
                     this.List = ShopStore.GetMyShopList.MyEatList
                     this.UpData = ShopStore.GetMyUpData.MyEatUpData
                     this.loadingShow = false
                     this.UpDown = false
                     this.init()
                 }else{
                     this.handleUpData(true)
                 }
                 break;
             case 1:
                 if( ShopStore.GetMyShopList.MyShopList.length ){
                     this.List = ShopStore.GetMyShopList.MyShopList
                     this.UpData = ShopStore.GetMyUpData.MyShopUpData
                     this.loadingShow = false
                     this.UpDown = false
                     this.init()
                 }else{
                     this.handleUpData(true)
                 }
                 break;
             case 3:
                 if( ShopStore.GetMyShopList.MyFreshList.length ){
                     this.List = ShopStore.GetMyShopList.MyFreshList
                     this.UpData = ShopStore.GetMyUpData.MyFreshUpData
                     this.loadingShow = false
                     this.UpDown = false
                     this.init()
                 }else{
                     this.handleUpData(true)
                 }
                 break;
         }
    }

    handleChangeNavIndex(data:NavListType,index:number){
        if( this.NavIndex != index ){
            if( !index ){
                delete this.UpData.orderStatuss
                this.UpData.pageNo = 1
                this.UpDown = true
                this.handleUpData( true )
            }else{
                this.NavIndex = index
                this.UpData.orderStatuss = data.state as string
                this.UpData.pageNo = 1
                this.UpDown = true
                this.handleUpData( true )
            }
        }
    }

    handleClickToPath(data:any){
        let ScrollData = ShopStore.GetShopScroll
        switch( IndexStore.GetShopState.type ){
            case 2:
                ScrollData.MySnackScroll = IndexStore.GetScrollNum
                break;
            case 0:
                ScrollData.MyEatScroll = IndexStore.GetScrollNum
                break;
            case 1:
                ScrollData.MyShopScroll = IndexStore.GetScrollNum
                break;
            case 3:
                ScrollData.MyFreshScroll = IndexStore.GetScrollNum
                break;
        }
        ShopStore.SetScrollType( ScrollData )
    }

    handlePullDown(){
        this.PullDown = false
        this.StopUpDown = false
        this.UpData.pageNo = 1
        MyOrderList({
            userId:this.userId,
            token:this.token
        },this.UpData).then( res=>{
            console.log( res )
            this.PullDown = true
            if( res.message.code === "200" ){
                if( res.data.orderList.length < this.UpData.pageSize ){
                    this.StopUpDown = true
                }
                this.List = res.data.orderList
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleUpDown(){
        if( this.StopUpDown )return;
        this.UpData.pageNo += 1
        this.UpDown = true
        this.handleUpData()
    }

    handleUpData(bool?:boolean){
        MyOrderList({
            userId:this.userId,
            token:this.token
        },this.UpData).then( res=>{
            this.loadingShow = false
            this.UpDown = false
            console.log( res )
            if( res.message.code === "200" ){
                if( res.data.orderList.length < this.UpData.pageSize ){
                    this.StopUpDown = true
                }
                if( bool ){
                    this.List = res.data.orderList
                }else{
                    this.List = this.List.concat( res.data.orderList )
                }
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }

    @Watch("UpData",{ deep:true })
    handleChangeUpData(newVal:MyOrderListType){
        let data = ShopStore.MyUpData
        switch( IndexStore.GetShopState.type ){
            case 2:
                data.MySnackUpData = newVal
                ShopStore.SetMyUpData(data)
                break;
            case 0:
                data.MyEatUpData = newVal
                ShopStore.SetMyUpData(data)
                break;
            case 1:
                data.MyShopUpData = newVal
                ShopStore.SetMyUpData(data)
                break;
            case 3:
                data.MyFreshUpData = newVal
                ShopStore.SetMyUpData(data)
                break;
        }
    }

    @Watch("List")
    handleChangeList(newVal:any){
        let data = ShopStore.GetMyShopList
        switch( IndexStore.GetShopState.type ){
            case 2:
                data.MySnackList = newVal
                ShopStore.SetMyShopList(data)
                break;
            case 0:
                data.MyEatList = newVal
                ShopStore.SetMyShopList(data)
                break;
            case 1:
                data.MyShopList = newVal
                ShopStore.SetMyShopList(data)
                break;
            case 3:
                data.MyFreshList = newVal
                ShopStore.SetMyShopList(data)
                break;
        }
    }
}
